import request from "@/axios/globalaxios"

export const testApi = () => {
    return request({
        method:'get',
        url:'/pdpm/task/calculateExtensionTime',
    })
}

export const jupyterhubTokens = () => {
  return request({
    method: 'get',
    url: '/tradelab/jupyterhub/jupyterhubTokens',
  });
};

export const addJupyterhubTokens = (data) => {
  return request({
    method: 'get',
    url: '/tradelab/jupyterhub/addJupyterhubTokens',
    params: data,
  });
};

export const getUrl = (data) => {
  return request({
    method: 'get',
    url: '/tradelab/aiEditor/getUrl',
    params: data,
  });
};